body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

/* ================================================= */
/*                ANT DESIGN REALTED                 */
/* ================================================= */

/*Modal.confirm btn*/
.ant-btn-primary {
  background-color: rgb(0, 87, 148);
}
.ant-btn-primary:not(:disabled):hover {
  background-color: rgb(26, 109, 161);
}
.ant-btn-default {
  color: rgb(62, 63, 66);
  border-color: rgb(188, 203, 203);
}
.ant-btn-default:not(:disabled):hover {
  color: rgb(0, 87, 148);
  border-color: rgb(0, 87, 148);
}

/* checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(0, 87, 148);
  border-color: rgb(0, 87, 148);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: rgb(0, 87, 148);
}
/* hover checkbox when checked */
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: rgb(0, 87, 148);
}
/* hover checkbox when not checked */
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: rgb(0, 87, 148);
}

/* remove card shadhow*/
.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}
